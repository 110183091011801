@font-face {
    font-family: "Logo";
    src: local("logofont"),
        url("../assets/logofont.ttf") format("truetype");
}

.brewbuttons>div:hover {
    color: red;
}

.beer-box {
    border: 1px solid black;
    margin-bottom: .75vw;
    padding: 10px;
}

#edit-button {
    background-color: rgb(116, 154, 2);
    color: white;
    width: fit-content;
    padding: .51vw;
    cursor: pointer;
}

/* .brewery-card-click:hover {
    border: 3px solid black;
} */

.brewery-card-logo {
    cursor: auto;
    height: 10vw;
    width: 10vw;
    box-sizing: border-box;
    object-fit: fill;
    border: 1px solid black;
}

.below-header {
    width: fit-content;
}

.my-page-header {
    font-size: 5vw;
    font-family: Logo;
}

#my-brewery-page {
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}

.brewery-main-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2vw;

}

.brewery-main-container {
    display: flex;
    margin: 1em;
    border: 0.5px solid grey;
}

.brewery-logo-container {
    display: flex;
    /* justify-content: space-between; */
    flex-direction: column;
    align-items: center;
    padding: .52vw;
}

.brewery-logo-container>* {
    margin: 1vw;
}

.brewery-info-container {
    display: flex;
    flex-direction: row;
    /* width: 60vw; */
    /* font-family: Logo; */
    width: 80vw;
    justify-content: space-between;

}

.brewery-logo-image {
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.edit-delete-beer-on-brewery-button {
    border: 1px burlywood;
    padding: .5vw;
    cursor: pointer;
    color: rgb(116, 154, 2);
    background-color: aquamarine;
    width: 20%;
}

#create-badge-on-brewery-button {
    border: 1px burlywood;
    padding: .5vw;
    cursor: pointer;
    color: rgb(116, 154, 2);
    /* background-color: red; */
    background-color: #1b8811;
    width: fit-content;
}

.brewtitle {
    font-size: 3vw;
}

.brewbuttons {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: fit-content;
    /* justify-content:; */
}

#create-badge-on-brewery-button:hover {
    color: red;
}

.brewery-info-description {
    display: flex;
    justify-content: space-between;
    width: 60vw;
    font-family: Logo;
}

.brewery-logo-subcontainer {
    display: flex;
    flex-direction: column;

}

.brewery-logo-info-container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.brewery-name-city-type-container {
    display: flex;
    flex-direction: column;
    margin-left: 30px;
    font-family: Logo;
    font-size: x-large;
    align-items: center;
}

.brewdescription {
    text-align: center;
}

.create-a-beer-button {
    border: 1px burlywood;
    padding: .5vw;
    cursor: pointer;
    color: rgb(116, 154, 2);
    background-color: aquamarine;
    width: 20%;
}

.individual-badges {
    display: flex;
    flex-direction: row;

}

.badges-container {
    font-family: Logo;
}
